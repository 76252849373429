import Swiper from "swiper/bundle";

export const bannerSlider = () => {
  const swiper = new Swiper(".swiper-container", {
    loop: true,
    slidesPerView: 1.46,
    spaceBetween: 25,
    centeredSlides: true,
    autoplay: {
      delay: 5000,
    },
    speed: 1000,
    pagination: {
      el: ".banner__pagination",
    },

    breakpoints: {
      769: {
        slidesPerView: 1.58,
        spaceBetween: 50,
      },
    },
  });
};
