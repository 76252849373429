export const hamburgerMenu = () => {
  const hamburger = document.querySelector(".js-hamburger-btn");

  const menu = document.querySelector(".js-menu");

  hamburger.addEventListener("click", () => {
    document.body.classList.toggle("menu-is-active");
    menu.classList.toggle("is-active");
    hamburger.classList.toggle("is-active");
  });
};
